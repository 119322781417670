<template>
  <camera-step :stepNumber="6" :imageInputs="imageInputs" />
</template>

<script>
import CameraStep from "@/components/vehicle/CameraStep.vue"; // Adjust the import path as needed

export default {
  name: "StepEleven",
  components: {
    CameraStep,
  },
  data() {
    return {
      imageInputs: [
        {
          id: 1,
          title: "Extensive Cleaning",
          type: "return",
          instructions: "",
          file: null,
          category: "Cleaning",
          required: false,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 2,
          title: "Extensive Cleaning",
          type: "return",
          instructions: "",
          file: null,
          category: "Cleaning",
          required: false,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 3,
          title: "Extensive Cleaning",
          type: "return",
          instructions: "",
          file: null,
          category: "Cleaning",
          required: false,
          video: "",
          image: "",
          inputs: [""],
        },
      ],
    };
  },
};
</script>
