<template>
  <div class="pb-5">
    <p>Please precondition the vehicle by following the below steps.</p>
    <div
      :class="{ 'mt-3': index != 0 }"
      v-for="(category, index) in checklist"
      :key="index"
    >
      <h6 class="mt-5">{{ category.title }}</h6>
      <p>{{ category.instruction }}</p>
      <ul>
        <li v-for="task in category.tasks" :key="task">{{ task }}</li>
      </ul>
      <p v-if="category.disclaimer" class="disclaimer">
        {{ category.disclaimer }}
      </p>
    </div>
    <button
      @click="finishStep"
      class="btn btn-primary mt-3"
      :disabled="loading"
    >
      <span v-if="!loading">Next</span>
      <span v-else
        ><div
          class="spinner-border"
          style="height: 1rem !important; width: 1rem !important"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div></span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "StepTen",
  data() {
    return {
      loading: false,
      checklist: [
        {
          title: "Precondition the battery.",
          instruction:
            "Please precondition the battery for greater charging efficiency.",
          tasks: [
            "Use the navigation and route to the nearest supercharger or",
            "Turn on the climate to 70 degrees farenheit",
          ],
        },
      ],
    };
  },
  methods: {
    async finishStep() {
      this.loading = true;
      const parentRoute = this.$route.matched[this.$route.matched.length - 2];
      if (parentRoute.name === "pickup") {
        this.loading = false;
        this.$router.push(
          "/pickup/" +
            this.$route.params.reservation_id +
            "/" +
            (parseInt(this.$route.meta.step) + 1).toString()
        );
      } else {
        this.loading = false;
        this.$router.push(
          "/return/" +
            this.$route.params.reservation_id +
            "/" +
            (parseInt(this.$route.meta.step) + 1).toString()
        );
      }
    },
  },
};
</script>
