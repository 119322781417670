<template>
  <camera-step :stepNumber="6" :imageInputs="imageInputs" />
</template>

<script>
import CameraStep from "@/components/vehicle/CameraStep.vue";

export default {
  name: "StepSix",
  components: {
    CameraStep,
  },
  data() {
    return {
      imageInputs: [
        {
          id: 1,
          title: "Driver Seat",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Seats",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 2,
          title: "Driver Carpet",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Carpet",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 3,
          title: "Driver Seat Side Controls",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Instruments",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 4,
          title: "Battery Life",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Instruments",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 5,
          title: "Miles",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Instruments",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 6,
          title: "Tire Pressue",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Instruments",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 7,
          title: "Deleted Keys",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Keys",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 8,
          title: "Center Console",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Console",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 9,
          title: "Center Console Overhead",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Console",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 10,
          title: "Center Console Overhead Open",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Console",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 11,
          title: "Front Roof Glass",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Glass",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 12,
          title: "Rear Roof Glass",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Glass",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 13,
          title: "Back Seat (Driver Side Angle)",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Seats",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 14,
          title: "Back Seat Carpet (Driver Side Angle)",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Carpet",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 15,
          title: "Rear Center Console",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Console",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 16,
          title: "Trunk",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Cargo",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 17,
          title: "Trunk Compartment",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Cargo",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 18,
          title: "Back Seat (Passenger Side Angle)",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Seats",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 19,
          title: "Back Seat Carpet (Passenger Side Angle)",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Carpet",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 20,
          title: "Passenger Seat",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Seats",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 21,
          title: "Passenger Carpet",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Carpet",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 22,
          title: "Passenger Seat Side Controls",
          type: "pickup",
          instructions: "1 photo",
          file: null,
          category: "Instruments",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 23,
          title: "Frunk",
          type: "pickup",
          instructions: "1 picture",
          file: null,
          category: "Cargo",
          required: true,
          video: "",
          image: "",
          inputs: [""],
        },
      ],
    };
  },
};
</script>
