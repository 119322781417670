<template>
  <camera-step :stepNumber="5" :imageInputs="imageInputs" />
</template>

<script>
import CameraStep from "@/components/vehicle/CameraStep.vue";

export default {
  name: "StepFive",
  components: {
    CameraStep,
  },
  data() {
    return {
      imageInputs: [
        {
          id: 1,
          category: "Bumpers",
          required: true,
          title: "Front Bumper",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video:
            "https://www.youtube.com/embed/bHQOa_epWSw?si=MBLob8cjiXLPpA2b",
          image: "",
          inputs: [""],
        },
        {
          id: 2,
          category: "Windshield",
          required: true,
          title: "Front Windshield",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 3,
          category: "Undercarriage",
          required: true,
          title: "Front Undercarriage",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 4,
          category: "Body",
          required: true,
          title: "Driver's Front Corner",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 5,
          category: "Body",
          required: true,
          title: "Driver's Front Quarter",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 6,
          category: "Wheels",
          required: true,
          title: "Driver's Front Wheel",
          type: "pickup",
          instructions: "Stand 1 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 7,
          category: "Tread Depth",
          required: true,
          title: "Driver's Front Wheel Tread Depth",
          type: "pickup",
          instructions: "Use instrument",
          file: null,
          video: "",
          image: "",
          inputs: ["tread_depth"],
        },
        {
          id: 8,
          category: "Doors",
          required: true,
          title: "Driver's Front Door",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 9,
          category: "Doors",
          required: true,
          title: "Driver's Rear Door",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 10,
          category: "Body",
          required: true,
          title: "Driver's Rear Quarter",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 11,
          category: "Wheels",
          required: true,
          title: "Driver's Rear Wheel",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 12,
          category: "Tread Depth",
          required: true,
          title: "Driver's Rear Wheel Tread Depth",
          type: "pickup",
          instructions: "Use instrument",
          file: null,
          video: "",
          image: "",
          inputs: ["tread_depth"],
        },
        {
          id: 13,
          category: "Body",
          required: true,
          title: "Driver's Rear Corner",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 14,
          category: "Bumpers",
          rrequired: true,
          title: "Rear Bumper",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 15,
          category: "Windshield",
          required: true,
          title: "Back Windshield",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 16,
          category: "Undercarriage",
          required: true,
          title: "Rear Undercarriage",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 17,
          category: "Body",
          required: true,
          title: "Passenger's Rear Corner",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 18,
          category: "Body",
          required: true,
          title: "Passenger's Rear Quarter",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 19,
          category: "Wheels",
          required: true,
          title: "Passenger's Rear Wheel",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 20,
          category: "Tread Depth",
          required: true,
          title: "Passenger's Rear Wheel Tread Depth",
          type: "pickup",
          instructions: "Use instrument",
          file: null,
          video: "",
          image: "",
          inputs: ["tread_depth"],
        },
        {
          id: 21,
          category: "Doors",
          required: true,
          title: "Passenger's Rear Door",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 22,
          category: "Doors",
          required: true,
          title: "Passenger's Front Door",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 23,
          category: "Body",
          required: true,
          title: "Passenger's Front Quarter",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 24,
          category: "Wheels",
          required: true,
          title: "Passenger's Front Wheel",
          type: "pickup",
          instructions: "Stand 1 step away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
        {
          id: 25,
          category: "Tread Depth",
          required: true,
          title: "Passenger's Front Wheel Tread Depth",
          type: "pickup",
          instructions: "Use instrument",
          file: null,
          video: "",
          image: "",
          inputs: ["tread_depth"],
        },
        {
          id: 26,
          category: "Body",
          required: true,
          title: "Passenger's Front Corner",
          type: "pickup",
          instructions: "Stand 3 steps away",
          file: null,
          video: "",
          image: "",
          inputs: [""],
        },
      ],
    };
  },
};
</script>
